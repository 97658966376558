<!--
 * @Description:dome 主页
 * @Author: shufei
 * @Date: 2021-11-08 16:37:23
 * @LastEditTime: 2021-11-20 11:44:12
 * @LastEditors: shufei
-->
<template>
  <div class="container">
    <van-cell is-link to="/public/mescroll-demo">mescroll 下拉刷新 / 上拉加载更多组件示例</van-cell>
    <van-cell is-link to="/public/popup-layer-demo">popupLayer 无限弹出层组件使用示例</van-cell>
    <van-cell is-link to="/public/lazyload-demo">lazyload 图片懒加载组件/指令使用示例 </van-cell>
    <van-cell is-link to="/public/draggable-demo">draggable 拖拽排序组件使用示例</van-cell>
    <van-cell is-link to="/public/tab-bar-demo">TabBar 底部导航组件使用示例</van-cell>
    <van-cell is-link to="/public/icon-font-demo">iconfont 使用示例</van-cell>
    <van-cell is-link to="/public/svg-demo">svg 组件使用示例</van-cell>
    <van-cell is-link to="/public/image-use-demo">img 组件使用示例</van-cell>
  </div>
</template>
<script>
export default {
  name: 'Public'
}
</script>
