<template>
  <div class="map-page">
    <div class="top-tabbar">
      <van-tabs v-model="activeTab" class="type-list" @click="tabClick">
        <van-tab id="tab0" title="全览"
          :class="map.id == '0' ? 'tab-item the-flex f-y-c  active' : 'tab-item the-flex f-y-c'"
          >
        </van-tab>
        <van-tab :title="item.name" :id="`tab${item.id}`"
          :class="map.id == item.id ? 'tab-item tab-item the-flex f-y-c active' : 'tab-item the-flex f-y-c'"
          v-for="(item, index) in localList" :key="index" v-if="item.pid == 0">
        </van-tab>
      </van-tabs>
    </div>
    <div class="left-tabbar">
      <div class="left-tabbar-li" @click="selLeftTabLi(index)" v-for="(item,index) in pointList" 
        :style="{
          display: 'flex',
          border: '1px solid #D3D3D3',
        }">
        <div>
          <img v-if="index == activeLeftItemIndex" :src="require('../../assets/images/icon/left-' + item.id + '.png')" style="width: 100%; height: 100%; " alt="">
          <img v-else="index == activeLeftItemIndex" :src="require('../../assets/images/icon/left-a-' + item.id + '.png')" style="width: 100%; height: 100%; " alt="">
        </div>
        <!-- <div style="line-height: 10px; text-align: center; margin-top: 2px;">{{ item.name }}</div> -->
      </div>
    </div>
    <div id="container"></div>
    <!-- <div class="marker-icon-list">
      <div class="marker-icon-item the-flex flex-center wrap" :id="`markerIcon${item.id}`"
        v-for="(item, index) in localList" :key="index" @click="markerClick(item, index)">
        <img src="../../assets/images/icon/location.png" alt="">
        <div class="marker-title">{{ item.name }}</div>
      </div>
    </div> -->
    <van-popup lazy-render v-model="bottomShow" position="bottom" :class="['bootom-popup', {unfold: unfoldFlag }]"
      :overlay="false" :safe-area-inset-bottom="true">
      <div v-if="bottomShow" class="footer-area">
        <div class="marker-footer the-flex f-y-c x-spc-btw">
          <div class="left the-flex f-y-c" v-if="activeLocal.properties">
            <img class="head-img" :src="activeLocal.properties.headimg" />
            <div>
              <div class="title">{{ activeLocal.name }}</div>
              <div class="text">{{ activeLocal.properties ? activeLocal.properties.title : '' }}</div>
            </div>
          </div>
          <div class="right" style="display: flex;">
            <audio ref="audioPlayer" @ended="onAudioEnded" :src="activeLocal.video_mp3" controls style="display: none;"></audio>
            <img v-if="activeLocal.video_mp3" class="nav-img" src="../../assets//images/icon/yy.png" style="margin-right: 10px;" @click="playAudio"/>
            <img class="nav-img" src="../../assets//images/icon/daohang.png" @click="getNav"/>
          </div>
          <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item></van-swipe-item>
        </van-swipe> -->
          <div class="unfold-area the-flex flex-center" @click="handleUnfold">
            <img src="../../assets/icons/unfold.png" class="img" />
          </div>
        </div>
        <div class="detail the-flex wrap">
          <div class="text"
            v-if="activeLocal.properties && activeLocal.properties.info && activeLocal.properties.info.text">
            <p class="text-p" v-for="(item, index) in (getItemText(activeLocal.properties.info.text))"
              :key="`item_${index}`">{{ item }}</p>
          </div>
          <div class="imgs the-flex wrap"
            v-if="activeLocal.properties && activeLocal.properties.info && activeLocal.properties.info.imgs">
            <img alt="" srcset="" v-for="(item, index) in activeLocal.properties.info.imgs" :key="`img_${index}`"
              :src="item" class="detail-img">
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
  export default {
    name: 'H5Map',

    data() {
      return {
        selectVillageId: 0,
        activeLeftItemIndex:0,
        Map: undefined,
        AMap: undefined,
        /* 是否加载完成 */
        loading: true,
        bottomShow: false,
        unfoldFlag: true,
        /* 手机高度 */
        phoneHeight: 0,
        /* 可滚动视图区域高度 */
        scrollviewHigh: 0,
        /* 数据列表 */
        listData: [],
        markerLayer: undefined,
        imageTileLayer: undefined,
        activeTab: 0,
        activePoint: 0,
        active: 0,
        pointList: [{
            id: 1,
            name: '全部',
          },
          {
            id: 2,
            name: '景点',
          },
          {
            id: 3,
            name: '游娱',
          },
          {
            id: 4,
            name: '餐饮',
          },
          {
            id: 5,
            name: '住宿',
          },
          {
            id: 6,
            name: '公共服务',
          }
        ],
        /* 是否有更多 */
        no_more: null,
        /* 一页多少条 */
        list_rows: 10,
        /* 当前第几页 */
        page: 1,
        labelSetting: {
          padding: 4,
          x: -22,
          y: -2,
          anchorX: -24,
          anchorY: -2,
          fontSize: 12,
          bgColor: '#fff',
          borderRadius: 16,
          borderColor: '#366B51',
          borderWidth: 2
        },
        localList: [],
        allPoint: {
          title: '全览11',
          id: '0',
          latitude: 29.106863,
          longitude: 118.6035
        },

        /* 分类数据 */
        categorys: [],
        /* 当前选中的类别 */
        type_active: 0,
        activeLocal: {},
        mapConfig: {
          viewMode: '2D', // 默认使用 2D 模式
          zoom: 12, // 地图级别
          center: [118.6035, 29.106863], // 地图中心点
          zooms: [12, 16]
        },
        map: {
          id: 0, // 使用 marker点击事件 需要填写id
          title: '',
          label: '',
          scale: 11.5,
          latitude: 29.097738120743,
          longitude: 118.60037881621633,
          covers: this.localList
        },
        // 是否播放
        is_play: false,
      }
    },
    async created() {
      var res = await this.$api.contacts.jsapiTicket(this.$route.query)
      // const data = res.data
      // wx.config({
      //   debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      //   appId: data.appId, // 必填，公众号的唯一标识
      //   timestamp: data.timestamp, // 必填，生成签名的时间戳 签名算法生成规则文档 https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#%E9%99%84%E5%BD%951-JS-SDK%E4%BD%BF%E7%94%A8%E6%9D%83%E9%99%90%E7%AD%BE%E5%90%8D%E7%AE%97%E6%B3%95
      //   nonceStr: data.nonceStr, // 必填，生成签名的随机串
      //   signature: data.signature, // 必填，签名
      //   jsApiList: ['openLocation'] // 必填，需要使用的JS接口列表
      // })
      wx.config({
					debug: false,
					appId: res.data.appId,
					timestamp: res.data.timestamp,
					nonceStr: res.data.nonceStr,
					signature: res.data.signature,
					jsApiList: ['openLocation']
				});
    },
    mounted() {
      let token = this.$route.query;
      this.indexList(token)
    },

    methods: {
      // 语音播放完自动关闭
      onAudioEnded() {
        this.$refs.audioPlayer.pause();
        this.is_play = !this.is_play
      },
      // 播放语音
      playAudio() {
        const audioPlayer = this.$refs.audioPlayer;
        this.is_play = !this.is_play
        if (this.is_play) {
          audioPlayer.play();
        } else {
          audioPlayer.pause();
        }
      },
      indexList(token) {
        this.$api.contacts.indexList(token).then(res => {
          if (res.code === 1) {
            this.localList = res.data
            this.initMap()
          }
        })
      },
      // 选择左侧分类
      selLeftTabLi(index) {
        this.activeLeftItemIndex = index
        this.initMap()

      },
      // 导航
      initMap() {
        // 高德
        AMapLoader.load({
            key: 'e16c4df2dcc40e4529b00543f2ecf52e', // 申请好的Web端开发者 Key，调用 load 时必填
            version: '2.0' // 指定要加载的 JS API 的版本，缺省时默认为 1.4.15
          })
          .then((AMap) => {
            // if (!this.AMap) {
              this.AMap = new AMap.Map('container', this.mapConfig)
            // }
            this.setMarkerList(AMap)
            // var imageLayer = new AMap.ImageLayer({
            //   url: require('../../assets/images/map/map.png'), // 图片 Url
            //   zIndex: 2,
            //   zooms: [3, 18] // 设置可见级别，[最小级别，最大级别]
            // })
            // this.AMap.add(imageLayer)
            var flexibleLayer = new AMap.TileLayer.Flexible({
              /**
               * 自定义切片属性，返回值为切片图片或 canvas
               * @param x: 切片横向编号
               * @param y: 切片纵向编号
               * @param z: 切片层级
               * @param success: 创建成功回调
               * @param fail: 创建失败回调
               */
              createTile: function(x, y, z, success, fail) {
                var img = document.createElement('img')
                img.onload = function() {
                  success(img)
                }
                img.crossOrigin = 'anonymous' // 必须添加，同时图片要有跨域头
                img.onerror = function() {
                  // console.log(x, y, z, require(`./${z}/${x}/${y}.png`))
                  fail()
                  return ''
                }
                img.src = `https://zhihuixiangcun-test.wychongyu.cn/${z}/${x}/${y}.png`;
                // try {
                //   // img.src = require(`./${z}/${x}/${y}.png`)
                //   console.log(`http://zhihuixiangcun.xinyunweb.com/daolan_image/${z}/${x}/${y}.png`);
                //   img.src = require(`http://zhihuixiangcun.xinyunweb.com/daolan_image/${z}/${x}/${y}.png`)
                // } catch (error) {
                //   console.log('erro182847r',error)
                //   img.src = ''
                // }
              },
              cacheSize: 256, // 内存中缓存的切片的数量上限
              zIndex: 2,
              zooms: [12, 16] // 设置可见级别，[最小级别，最大级别]
            })
            this.AMap.add(flexibleLayer)
          })
          .catch((e) => {
            console.error(e) // 加载错误提示
          })
      },


      setMarkerList(AMap) {
        const list = this.localList
        const activeTabIndex = this.activeTab  - 1
        const activeTabItem = this.localList[activeTabIndex]
        for (let index = 0; index < list.length; index++) {
          const item = list[index]
          if(item.child.length != 0) {
            for (let index = 0; index < item.child.length; index++) {
              if(item.child[index].isShow && (this.activeLeftItemIndex == 0 || this.activeLeftItemIndex == item.child[index].type) && (activeTabIndex < 0 || activeTabItem.id == item.child[index].pid)) {
                if (item.child[index].type == 0) {
                  var content =  `<div class="marker-icon-item the-flex flex-center wrap"></div>`;
                } else {
                  var content =  `<div class="marker-icon-item the-flex flex-center wrap">
                  <img src="${require('../../assets/images/icon/posionicon-' + (item.child[index].type) + '.png')}" alt="">
                </div>`;
                }


                let marker = new AMap.Marker({
                  content: content, //自定义点标记覆盖物内容
                  position: new AMap.LngLat(item.child[index].longitude, item.child[index].latitude),
                  offset: new AMap.Pixel(-13, -30), //相对于基点的偏移位置
                });

                marker.setLabel({
                      direction: "centre",
                      offset: new AMap.Pixel(0, 60), //设置文本标注偏移量
                      // content: `<div class='marker-title'>${item.child[index].name}</div>`, //设置文本标注内容
                      content: '', //设置文本标注内容
                    });
                      //setLabel是marker点底部的提示标签

                marker.on('click', () => {
                  this.map.id = item.child[index].id
                  // this.activeTab = index ? index + 1 : 0
                  if (item.child[index].id !== '0') {
                    this.activeLocal = item.child[index]
                    this.AMap.setZoomAndCenter(16, [item.child[index].longitude, item.child[index].latitude], false, 1000)
                    this.bottomShow = true
                    this.unfoldFlag = true
                  } else {
                    this.activeLocal.id = '0'
                    this.bottomShow = false
                    this.AMap.setZoomAndCenter(12, [item.child[index].longitude, item.child[index].latitude], false, 1000)
                  }
                })

                this.AMap.add(marker);
              }
            }
          }
        }

      },



      markerClick(item) {
        this.map.id = item.id
        // this.activeTab = index ? index + 1 : 0
        if (item.id !== '0') {
          this.activeLocal = item
          this.AMap.setZoomAndCenter(16, [item.longitude, item.latitude], false, 1000)
          this.bottomShow = true
          this.unfoldFlag = true

          if (this.is_play) {
            const audioPlayer = this.$refs.audioPlayer;
            this.is_play = false;
            audioPlayer.pause();
          }

        } else {
          this.activeLocal.id = '0'
          this.bottomShow = false
          this.AMap.setZoomAndCenter(13, [item.longitude, item.latitude], false, 1000)
        }
      },
      handleUnfold() {
        this.unfoldFlag = !this.unfoldFlag
      },
      tabClick() {
        // this.is_play = !this.is_play;// 跳转tab切换语音状态
          if (this.is_play) {
            const audioPlayer = this.$refs.audioPlayer;
            this.is_play = false;
            audioPlayer.pause();
          }
        let item = this.allPoint
        let zoom = 13
        if (this.activeTab > 0) {
          const index = this.activeTab - 1
          item = this.localList[index]
          zoom = 15
          this.activeLocal = item
          this.bottomShow = true
          this.unfoldFlag = true

          if (item.pid == 0) {
            this.localList.forEach(i => {
              if (i.pid == item.id) {
                i.isShow = true;
              } else {
                i.isShow = false;
              }
            })
          }
        } else {
          this.activeLocal = undefined
          this.bottomShow = false
          this.localList.forEach(i => {
            i.isShow = false;
          });
        }
        this.initMap()

        setTimeout(() => {
         this.AMap.setZoomAndCenter(zoom, [item.longitude, item.latitude], false, 1000)
        }, 300)
      },
      getNav() {
          //3. 只能通过跳转url传参
          wx.miniProgram.navigateTo({
            url: `/pages/webview/webview?longitude=${this.activeLocal.show_lng}&latitude=${this.activeLocal.show_lat}&title=${this.activeLocal.title}`
          });

        // wx.miniProgram.postMessage({ 
        //   data:{
        //     'longitude': this.activeLocal.longitude,
        //     'latitude': this.activeLocal.latitude
        //   } 
        // });
        // //跳转到小程序页面，触发向小程序发送消息
        // wx.miniProgram.reLaunch({
        //   url: 'pages/location/index' 
        // })
        // console.log(111111,  wx.openLocation)
        // wx.openLocation({
        //   type: 'gcj02',
        //   longitude: this.activeLocal.longitude,
        //   latitude: this.activeLocal.latitude,
        //   name: this.activeLocal.properties.title,
        //   success: (res) => {
        //     console.log(2222, res)
        //   },
        //   error: (e) => {
        //     console.log(3333, e)
        //   }
        // })
      },
      getItemText: (item) => {
        if (typeof item === 'string') {
          return [item]
        }
        return item
      }
    }
  }
</script>

<style lang="scss">
  .map-page {
    #container {
      width: 100vw;
      height: 100vh;
    }

    .marker-icon-item {
      font-size: 14px;
      width: 48px;

      img {
        width: 24px;
        height: 32px;
      }
    }

    .marker-title {
      width: 100%;
      font-size: 8px;
      text-align: center;
      color: #366B51;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 20px;
    }

    .top-tabbar {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
    }

    .left-tabbar {
      position: fixed;
      top: 25%;
      left: 10px;
      z-index: 999;
      width: 45px;

      // .left-tabbar-li {
      //   height: 50px;
      //   font-size: 10px;
      // }
    }

    .type-list {
      width: 100vw;

      .van-tabs__line {
        background-color: #34C759;
        width: 16px;
        height: 3px;
        border-radius: 1px;
      }

      .van-tab {
        padding: 0 15px;
        font-size: 16px;
        height: 48px;
        line-height: 48px;
        white-space: nowrap;

        // border-bottom: 4rpx solid #FFFFFF;
        .van-tab__text {
          height: 22px;
          font-size: 14px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.6);
          line-height: 22px;
        }

        &.van-tab--active {
          // border-bottom: 4rpx solid;
          // @include border_color('border_color');
          margin-bottom: 0;
          position: relative;

          .van-tab__text {
            color: #34C759;
            font-weight: 600;
          }
        }
      }
    }

    .bootom-popup {
      width: 100%;
      background: transparent;
      box-sizing: border-box;
      padding: 0 16px;

      .footer-area {
        background: #fff;
        box-sizing: border-box;
        border-radius: 8px;
      }

      .marker-footer {
        position: relative;
        background: #fff;
        padding: 16px;
        padding-bottom: 8px;
        box-sizing: border-box;
        z-index: 999;
        border-radius: 8upx;
        border-radius: 16px 16px 0 0;

        .left {
          .head-img {
            height: 60px;
            width: 60px;
            margin-right: 8px;
            border-radius: 8px;
          }

          .title {
            font-size: 18px;
            color: #333;
            font-weight: 600;
            line-height: 48upx;
            margin-bottom: 16upx;
          }

          .text {
            font-size: 14px;
            color: #999;
          }
        }

        .unfold-area {
          position: absolute;
          top: 4px;
          left: 50%;
          transform: translateX(-50%);
          width: 40px;
          height: 14px;
          background: #eee;
          border-radius: 7px;

          img {
            width: 24px;
            object-fit: cover;
            margin-left: 2px;
            transition: all 0.2s;
            transform: rotate(0deg);
          }
        }

        .nav-img {
          width: 40px;
          height: 40px;
        }
      }

      .detail {
        background: #fff;
        padding: 0 16px;
        font-size: 14px;
        text-indent: 2em;
        overflow-y: auto;
        transition: all 0.2s;
        max-height: 0;
        opacity: 0;

        .text {
          .text-p {
            margin-bottom: 8px;
          }
        }

        .detail-img {
          width: 100%;
          margin-bottom: 8px;
          border-radius: 4px;
        }
      }

      &.unfold {
        .detail {
          max-height: 58vh;
          opacity: 1;
        }

        .unfold-area img {
          transform: rotateX(180deg);
        }
      }
    }
  }
</style>
